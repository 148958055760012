@import 'variables';

@mixin slant($position) {
	// IF position === after then put some padding at the bottom of the element for the slant to live in
	@if ($position == after) {
		& {
			margin-bottom: $slant-padding;
			@media (min-width: $lg) {
				margin-bottom: #{$slant-padding * 2};
			}
		}
	}

	// Create slant
	&::#{$position} {
		content: '';
		position: absolute;
		z-index: -1;
		left: 0;
		width: 100%;
		height: 100%;
		transform-origin: top left;
		background: inherit;
		transform: skewY($slant-angle);

		// If slant at top, move up to meet the downward slant
		@if ($position == before) {
			top: -$slant-padding;
			@media (min-width: $lg) {
				top: -#{$slant-padding * 2};
			}
		} @else {
			top: 0;
		}
	}

	@media (min-width: $slant-intercept) {
		&::#{$position} {
			transform: skewY(2deg);
		}
	}
}
